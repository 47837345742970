<template>
  <div class="chart-container">
    <div v-if="error" class="chart-error">
      <p>{{ error }}</p>
    </div>
    <canvas v-if="!error" ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  name: "ChartComponent",
  props: {
    chartData: {
      type: Object,
      required: true,
      validator: (value) => {
        return value?.datasets?.length > 0;
      }
    },
    chartType: {
      type: String,
      default: 'line',
      validator: (value) => {
        return ['line', 'bar', 'pie', 'doughnut', 'radar'].includes(value);
      }
    },
    chartOptions: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      chartInstance: null,
      error: null,
      retryCount: 0,
      maxRetries: 5,
      debounceTimeout: null,
    };
  },
  methods: {
    destroyChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
        this.chartInstance = null;
      }
    },
    createChart() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.destroyChart();
        this.$nextTick(() => {
          try {
            const canvas = this.$refs.chartCanvas;
            if (!canvas || !document.body.contains(canvas)) {
              throw new Error('Canvas element not found');
            }

            const ctx = canvas.getContext('2d');
            if (!ctx) {
              throw new Error('Could not get canvas context');
            }

            if (!this.chartData?.datasets?.length) {
              throw new Error('No valid chart data available');
            }

            // Clone data to prevent external mutations
            const chartData = JSON.parse(JSON.stringify(this.chartData));

            this.chartInstance = new Chart(ctx, {
              type: this.chartType,
              data: chartData,
              options: {
                ...this.chartOptions,
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                  duration: 0, // Disable animations for stability
                },
                devicePixelRatio: window.devicePixelRatio,
              },
            });
            this.error = null; // Clear error if chart creation is successful
            this.retryCount = 0; // Reset retry count on success
          } catch (error) {
            console.error('Error creating chart:', error);
            this.error = error.message || 'Error creating chart';
            if (this.retryCount < this.maxRetries) {
              this.retryCount++;
              setTimeout(this.createChart, 1000); // Retry after 1 second
            }
          }
        });
      }, 100); // Debounce delay of 100ms
    }
  },
  watch: {
    chartData: {
      handler(newVal) {
        if (!newVal?.datasets?.length) {
          this.destroyChart();
          return;
        }
        this.createChart();
      },
      deep: true
    },
    chartType() {
      this.createChart();
    },
    chartOptions: {
      handler() {
        this.createChart();
      },
      deep: true
    }
  },
  mounted() {
    this.createChart();
  },
  beforeUnmount() {
    this.destroyChart();
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
  },
  activated() {
    this.createChart();
  },
  deactivated() {
    this.destroyChart();
  }
};
</script>

<style>
.chart-container {
  width: 100%;
  height: 40vh;
  margin: auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
}

.chart-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #dc3545;
}

@media (max-width: 600px) {
  .chart-container {
    max-height: 40vh;
    padding: 0;
    box-shadow: none;
  }
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
